/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { NumericMetrics, BarChart, LineChart, Select } from '../../components';
import { Entry, Customer, Proposal, Deal } from '../../entity';

import theme from '../../theme';
import { Container } from './styled';
import { AuthContext } from '../../context/auth';
import {
  blipDashboardService,
  partnerService,
} from '../../service';
import HorizontalBarChart from '../../components/HorizontalBarChart';
import FunnelChart from '../../components/FunnelChart';
import { ResponsiveRow } from '../../shared-styled';
import DateRange from '../../components/DateRange';
import { EPeriodDaysName, IDateRange } from '../../entity/datetime.entity';
import { message } from 'antd';
import { LoadingContext } from '../../context/loading';

export default function Dashboard ()  {
  const { user } = useContext(AuthContext);
  const { setLoading: setLoadingContext } = useContext(LoadingContext);
  const [date, setDate] = useState<IDateRange>();

  const [avgNegotiationsPrice, setAvgNegotiationsPrice] = useState<number>(0);
  const [totalNegotiationsPrice, setTotalNegotiationsPrice] = useState<number>(0);
  const [firstPortionPrice, setFirstPortionPrice] = useState<number>(0);

  const [access, setAccess] = useState<any>([]);
  const [hour, setHour] = useState<any[]>([]);
  const [Others, setOthers] = useState<any>(undefined);
  const [documentConfirmations, setDocumentConfirmations] = useState<number>(0);

  const [proposals, setProposals] = useState<number>(0);
  const [negotiations, setNegotiations] = useState<number>(0);
  const [deals, setDeals] = useState<any[]>([]);

  const [dailyAccessData, setDailyAccessData] = useState<any[]>([]);

  const [dailyTotalDealAmountData, setDailyTotalDealAmountData] = useState<any[]>([]);

  const [loadingAvgNegotiationsPrice, setLoadingAvgNegotiationsPrice] = useState<any>(true);
  const [loadingTotalNegotiationsPrice, setLoadingTotalNegotiationsPrice] = useState<any>(true);
  const [loadingFirstPortionPrice, setLoadingFirstPortionPrice] = useState<any>(true);
  const [loadingOthers, setLoadingOthers] = useState<any>(true);
  const [loadingAccess, setLoadingAccess] = useState<any>(true);
  const [loadingHour, setLoadingHour] = useState<any>(true);
  const [loadingDocumentConfirmations, setLoadingDocumentConfirmations] = useState<any>(true);
  const [loadingProposals, setLoadingProposals] = useState<any>(true);
  const [loadingNegotiations, setLoadingNegotiations] = useState<any>(true);
  const [loadingDailyAccessData, setLoadingDailyAccessData] = useState<any>(true);
  const [loadingDailyTotalDealAmountData, setLoadingDailyTotalDealAmountData] = useState<any>(true);
  const [loadingShipmentAvgs, setLoadingShipmentAvgs] = useState<any>(true);
  const [loadingDeals, setLoadingDeals] = useState<any>(true);
  const [creditorList, setCreditorList] = useState<any>([]);
  const [creditor, setCreditor] = useState<any>(0);

  const [shipmentAvgs, setShipmentAvgs] = useState<any>({
    Others: 0,
    accesses: 0,
    cpc: 0,
    proposals: 0,
    negotiations: 0,
    totalNegotiationsPrice: 0,
    firstPortionPrice: 0,
    avgNegotiationsPrice: 0,
  });

  const ControllerRequisition = new AbortController();

  const fetchOthers = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: any = await blipDashboardService.getByDay(partnerId, { startDate, endDate, signal, creditor });

      setOthers(res.others);
      setLoadingOthers(false);
      setAccess(res.access);
      setLoadingAccess(false);
      setDocumentConfirmations(res.confirmation);
      setLoadingDocumentConfirmations(false);
      setProposals(res.proposal);
      setLoadingProposals(false);
      setNegotiations(res.negotiations);
      setLoadingNegotiations(false);
    } catch (error: any) {
      setLoadingOthers(false);
      setLoadingAccess(false);
      setLoadingDocumentConfirmations(false);
      setLoadingProposals(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchDailyAccessData = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: any[] = await blipDashboardService.getAccessInfo(
          partnerId,
          {
            startDate,
            endDate,
            days: date?.period.numberOfDays,
            signal,
            creditor,
          },
        );

      setDailyAccessData(res);
      setLoadingDailyAccessData(false);
    } catch (error: any) {
      setLoadingDailyAccessData(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchHour = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: any[] = await blipDashboardService.getHourInfo(partnerId, { startDate, endDate, signal, creditor });

      setLoadingHour(false);
      setHour(res);
    } catch (error: any) {
      setLoadingHour(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchCreditors = async (partnerId: number, signal?: AbortSignal) => {
    try {
      const res: any[] = await partnerService.getPartnerCreditors(partnerId, signal);

      const resAll = res.concat({creditorId: 0, creditorName: 'Todos' })
      setCreditorList(resAll);
      setCreditor(0);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchDeals = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: Deal[] = await blipDashboardService.listDeals(partnerId, { startDate, endDate, signal, creditor });

      setDeals(res);
      setLoadingDeals(false);
    } catch (error: any) {
      setLoadingDeals(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchShipmentAvgs = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: any = await blipDashboardService.getShipmentAvgs(partnerId, { startDate, endDate, signal, creditor });

      setShipmentAvgs(res);
      setLoadingShipmentAvgs(false);
    } catch (error: any) {
      setLoadingShipmentAvgs(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const calcDealsAvgTicket = async () => {
    const portionAmount: number[] = [];
    const amount: number[] = [];
    // eslint-disable-next-line array-callback-return
    for (let deal of deals) {
      portionAmount.push(deal.portion_amount);
      amount.push(deal.amount);
    }

    const reducer = (accumulator: number, curr: number) => accumulator + curr;

    setFirstPortionPrice(portionAmount.length > 0 ? portionAmount.reduce(reducer) : 0);

    setAvgNegotiationsPrice(
      portionAmount.length > 0 ? portionAmount.reduce(reducer) / negotiations : 0
    );
    setTotalNegotiationsPrice(amount.length > 0 ? amount.reduce(reducer) : 0);

    setLoadingFirstPortionPrice(false);
    setLoadingTotalNegotiationsPrice(false);
    setLoadingAvgNegotiationsPrice(false)
  };

  const fetchDailyNegotiationsData = async (
    partnerId: number,
    startDate?: string,
    endDate?: string,
    signal?: AbortSignal,
    creditor?: any,
  ) => {
    try {
      const res: any[] = await blipDashboardService.getNegotiationsByDay(
        partnerId,
        {
          startDate,
          endDate,
          days: date?.period.numberOfDays,
          signal,
          creditor,
        },
      );

      setDailyTotalDealAmountData(res);
      setLoadingDailyTotalDealAmountData(false);
    } catch (error: any) {
      setLoadingDailyTotalDealAmountData(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await calcDealsAvgTicket();
    })();
  }, [deals, date]);

  useEffect(() => {
    (async () => {
      if (user && user.partner.id && date) {
        setLoadingContext(false);

        await Promise.all([
          fetchOthers(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor), 
          fetchDeals(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
          fetchShipmentAvgs(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
          fetchHour(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
          fetchDailyAccessData(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
          fetchDailyNegotiationsData(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
        ])
      }
    })();
    return () => ControllerRequisition.abort();
  }, [user, date, creditor]);

  useEffect(() => {
    (async() => {
      if(user && user.partner.id) {
        await fetchCreditors(user.partner.id, ControllerRequisition.signal);
      }
    })();
  }, [user]);

  return (
    <Container>
      <h2>Dashboard Blip</h2>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <DateRange
          period={EPeriodDaysName.WEEK}
          onChangeDate={(dateRange: IDateRange) => setDate(dateRange)}
        />
        <div style={{width: '20%', marginBottom: '10px', marginLeft: '10px', display:'flex', flexDirection: 'column', justifyContent: 'end' }}>
          <span>Credor:</span>
          <Select
            options={creditorList}
            value={creditor}
            onChange={(e) => setCreditor(e)}
            optionKeys={{ value: 'creditorId', displayName: 'creditorName' }}
            placeholder='Credor'
            firstDisabled={true}
          />
        </div>

      </div>

      <div className="metrics">
        <ResponsiveRow>
          <NumericMetrics
            title="Acessos"
            loading={ loadingAccess || loadingOthers || loadingShipmentAvgs }
            tooltip='Quantidade total de à página de confirmação de CPF no período'
            data={{
              period: date?.period,
              value: access,
              compAvg: shipmentAvgs.accesses,
            }}
            name="blip_access"
            download={{ date }}
            creditor={creditor}
          ></NumericMetrics>

          <NumericMetrics
            title="CPC"
            loading={ loadingAccess || loadingDocumentConfirmations || loadingShipmentAvgs }
            tooltip='Quantidade total de confirmações de CPF no período'
            data={{
              period: date?.period,
              value: documentConfirmations,
              comparatorValue: access,
              compAvg: shipmentAvgs.cpc,
            }}
            name="blip_confirmation"
            download={{ date }}
            creditor={creditor}
          ></NumericMetrics>

          <NumericMetrics
            title="Propostas"
            loading={ loadingProposals || loadingDocumentConfirmations || loadingShipmentAvgs }
            tooltip='Quantidade total de propostas selecionadas e aceitas pelos clientes no período'
            data={{
              period: date?.period,
              value: proposals,
              comparatorValue: documentConfirmations,
              compAvg: shipmentAvgs.proposals,
            }}
            name="blip_proposal"
            download={{ date }}
            creditor={creditor}
            ></NumericMetrics>

          <NumericMetrics
            title="Negociações"
            loading={ loadingNegotiations || loadingProposals || loadingShipmentAvgs }
            tooltip='Quantidade total de negociações realizadas pelos clientes no período'
            data={{
              period: date?.period,
              value: negotiations,
              comparatorValue: proposals,
              compAvg: shipmentAvgs.negotiations,
            }}
            name="blip_negotiation"
            download={{ date }}
            creditor={creditor}
            ></NumericMetrics>

          <NumericMetrics
            title="Outros"
            loading={ loadingOthers || loadingShipmentAvgs }
            tooltip='Quantidade de interações em outros serviços no período'
            data={{
              period: date?.period,
              value: Others,
              comparatorValue: documentConfirmations,
              compAvg: shipmentAvgs.Others,
            }}
            name="blip_other"
            download={{ date }}
            creditor={creditor}
            ></NumericMetrics>
        </ResponsiveRow>
        <ResponsiveRow>
          <NumericMetrics
            title="Total negociado"
            loading={ loadingDailyAccessData || loadingTotalNegotiationsPrice || loadingShipmentAvgs }
            data={{
              period: date?.period,
              value: totalNegotiationsPrice,
              compAvg: shipmentAvgs.totalNegotiationsPrice,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            title="1ª parcela"
            loading={ loadingDailyAccessData || loadingFirstPortionPrice || loadingShipmentAvgs }
            data={{
              period: date?.period,
              value: firstPortionPrice,
              compAvg: shipmentAvgs.firstPortionPrice,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            title="Ticket médio"
            tooltip="Valor das somas da 1ª parcela dividido com a quantidade de negociações"
            loading={ loadingDailyAccessData || loadingAvgNegotiationsPrice || loadingShipmentAvgs }
            data={{
              period: date?.period,
              value: avgNegotiationsPrice,
              compAvg: shipmentAvgs.avgNegotiationsPrice,
            }}
            showCurrency
          ></NumericMetrics>
        </ResponsiveRow>

        <ResponsiveRow>
          <BarChart
            loading={ loadingDailyAccessData }
            title="Acessos únicos por dia"
            data={dailyAccessData}
            colors={[theme.colors.red, theme.colors.healthy]}
            keys={['values']}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Quantidade'}
            label={'Acessos'}
          ></BarChart>
          <BarChart
            loading={ loadingDailyTotalDealAmountData }
            title="Valor total dos acordos fechados por dia (1ª parcela)"
            data={dailyTotalDealAmountData.map((t) => ({...t, Valor: t.values}))}
            colors={[theme.colors.blue]}
            keys={['Valor']}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Valor fechado'}
            prefix={'R$ '}
            formatPattern={'.2s'}
          ></BarChart>
        </ResponsiveRow>

        <ResponsiveRow>
          <FunnelChart
            loading={
              loadingAccess
              || loadingDocumentConfirmations
              || loadingProposals
              || loadingDeals
              || loadingOthers
            }
            title="Funil de negociação"
            data={[
              { etapa: 'Acessos', values: access, fill: '#faa000' },
              { etapa: 'CPC', values: documentConfirmations, fill: '#8c00ff' },
              { etapa: 'Propostas', values: proposals, fill: '#f70a0a' },
              { etapa: 'Negociações', values: negotiations, fill: '#00d9ff' },
              { etapa: 'Outros', values: Others, fill: '#4ace0a' },
            ]}
            colors={[theme.colors.tertiary]}
            keys={['values']}
            indexBy={'etapa'}
            axisBottomName={'Quantidade'}
            label={'Quantidade'}
          ></FunnelChart>
          <LineChart
            loading={ loadingHour }
            title="Gráfico por hora"
            data={hour}
            prefix={''}
            formatPattern={'.0s'}
            suffix="" 
          ></LineChart>
        </ResponsiveRow>
      </div>
    </Container>
  );
};
