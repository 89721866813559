import * as iquiteiSvc from './iquiteiApi.service';
import { formattedDate } from '../utils/date-format';

export async function getByDay(partnerId, config): Promise<any> {
  try {
    const { startDate, endDate, creditor, signal } = config;
    const res: any = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/blip/info?start_date=${startDate}&end_date=${endDate}&creditor=${creditor}`, { signal });
    const { data } = res;

    return data.result[0];
  } catch (error: any) {
    const err = error.statusCode !== 500 ? error.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function getAccessInfo(partnerId, config): Promise<any> {
  try {
    const { startDate, endDate, creditor, signal } = config;
    const res: any = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/blip/access?start_date=${startDate}&end_date=${endDate}&creditor=${creditor}`, { signal });
    const { data } = res;

    return data.result.map((item: any) => {
      return {
        ...item,
        day: formattedDate(new Date(item.day), 'dd/MM'),
      };
    });
  } catch (error: any) {
    const err = error.statusCode !== 500 ? error.error : 'Ocorreu um erro';
    throw err;
  }
}


export async function getHourInfo(partnerId, config): Promise<any> {
  try {
    const { startDate, endDate, creditor, signal } = config;
    const res: any = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/blip/hour?start_date=${startDate}&end_date=${endDate}&creditor=${creditor}`, { signal });
    const { data } = res;

    return data;
  } catch (error: any) {
    const err = error.statusCode !== 500 ? error.error : 'Ocorreu um erro';
    throw err;
  }
}


export async function listDeals(partnerId, config): Promise<any> {
  try {
    const { startDate, endDate, creditor, signal } = config;
    const res: any = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/blip/negotiate?start_date=${startDate}&end_date=${endDate}&creditor=${creditor}`, { signal });
    const { data } = res;

    return data.result;
  } catch (error: any) {
    const err = error.statusCode !== 500 ? error.error : 'Ocorreu um erro';
    throw err;
  }
}


export async function getNegotiationsByDay(partnerId, config): Promise<any> {
  try {
    const { startDate, endDate, creditor, signal } = config;
    const res: any = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/blip/negotiations?start_date=${startDate}&end_date=${endDate}&creditor=${creditor}`, { signal });
    const { data } = res;

    return data.result.map((item: any) => {
      return {
        ...item,
        day: formattedDate(new Date(item.day), 'dd/MM'),
      };
    });;
  } catch (error: any) {
    const err = error.statusCode !== 500 ? error.error : 'Ocorreu um erro';
    throw err;
  }
}


export async function getShipmentAvgs(partnerId, config): Promise<any> {
  try {
    const { startDate, endDate, creditor, signal } = config;
    const res: any = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/blip/average?start_date=${startDate}&end_date=${endDate}&creditor=${creditor}`, { signal });

    let data = {
      others: 0,
      accesses: 0,
      cpc: 0,
      proposals: 0,
      negotiations: 0,
      totalNegotiationsPrice: 0,
      firstPortionPrice: 0,
      avgNegotiationsPrice: 0,
    };

    res.data.result.forEach((item: any) => {
      return (data = {
        others: item.Field === 'Outros' ? item.Average : data.others,
        accesses: item.Field === 'Acessos' ? item.Average : data.accesses,
        cpc: item.Field === 'CPC' ? item.Average : data.cpc,
        proposals: item.Field === 'Propostas' ? item.Average : data.proposals,
        negotiations: item.Field === 'Negociações' ? item.Average : data.negotiations,
        totalNegotiationsPrice:
          item.Field === 'Total Negociado' ? item.Average : data.totalNegotiationsPrice,
        firstPortionPrice: item.Field === '1 Parcela' ? item.Average : data.firstPortionPrice,
        avgNegotiationsPrice:
          item.Field === 'Ticket Médio' ? item.Average : data.avgNegotiationsPrice,
      });
    });

    return data;
  } catch (error: any) {
    const err = error.statusCode !== 500 ? error.error : 'Ocorreu um erro';
    throw err;
  }
}
