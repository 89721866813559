import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Login from './pages/login';
import SplashScreen from './pages/splash-screen';
import { isLoggedIn } from './service/auth.service';
import { DashContainer } from './components';
import Dashboard from './pages/dashboard';
import DashboardBlip from './pages/dashboardBlip';
import { routeParams } from './config/routes.config';
import ForgotPassword from './pages/forgot';
import RegisterPartner from './pages/register/partner';
import RegisterEmployee from './pages/register/employee';
import {
  ManageShipmentsNegotiation,
  ShipmentDetailsNegotiation,
  ShipmentErrorsNegotiation,
  GenerateCampaignNegotiation,
} from './pages/shipments/negotiation';
import {
  ManageShipmentsPreventive,
  ShipmentDetailsPreventive,
  ShipmentErrorsPreventive,
  GenerateCampaignPreventive,
} from './pages/shipments/preventive';
import UserProfile from './pages/user-profile';
import GenerateReports from './pages/generate-reports';
import LatestNews from './pages/latest-news';
import HealthCheck from './pages/health-check';
import SystemErrors from './pages/system-errors';
import SystemIdError from './pages/system-errors/system-id-error';
import CreditorConfiguration from './pages/configuration';
import ProposalsDeny from './pages/proposals-deny';
import Error from './pages/error';
import Integration from './pages/configuration/Integration'
import RegisterCreditor from './pages/register/creditor';
import DashboardFinance from './pages/dashboardFinance';
import BlockList from './pages/blocklist';

interface PrivateRouteProps {
  component: any;
  path: any;
  exact?: boolean;
}

export default function Routes() {
  const history = useHistory();
  const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, path, ...rest }) => {
    return (
      <Route
        path={path}
        exact
        {...rest}
        render={(props) => {
          return isLoggedIn() ? (
            <DashContainer children={<Component {...props} />} />
          ) : (
            <Redirect to={routeParams.ROUTE_ROOT} />
          );
        }}
      />
    );
  };

  return (
    <Router>
      <Switch>
        {/* <ErrorBoundary
          FallbackComponent={Error}
          onError={(error, errorInfo) => {
            localStorage.clear();
            window.location.href = `${routeParams.ROUTE_URL}/error`;
            return console.error({ error, errorInfo });
          }}
        > */}
          <Route path={routeParams.ROUTE_ROOT} exact={true} render={() => <SplashScreen />} />
          <Route path={routeParams.ROUTE_LOGIN} render={() => <Login />} />

          <Route path={routeParams.ROUTE_FORGOT_PASSWORD} render={() => <ForgotPassword />} />

          <PrivateRoute path={routeParams.ROUTE_DASHBOARD} component={Dashboard} />

          <PrivateRoute path={routeParams.ROUTE_BLIP_DASHBOARD} component={DashboardBlip} />

          <PrivateRoute path={routeParams.ROUTE_FINANCE_DASHBOARD} component={DashboardFinance} />

          <PrivateRoute path={routeParams.ROUTE_REGISTER_PARTNER} component={RegisterPartner} />

          <PrivateRoute path={routeParams.ROUTE_REGISTER_EMPLOYEE} component={RegisterEmployee} />
          <PrivateRoute path={routeParams.ROUTE_REGISTER_CREDITOR} component={RegisterCreditor} />

          <PrivateRoute
            path={routeParams.campaigns.ROUTE_CAMPAIGNS_LIST_PREVENTIVE}
            component={ManageShipmentsPreventive}
          />
          <PrivateRoute
            path={routeParams.campaigns.ROUTE_CAMPAIGN_DETAILS_PREVENTIVE}
            component={ShipmentDetailsPreventive}
          />
          <PrivateRoute
            path={routeParams.campaigns.ROUTE_CAMPAIGN_ERRORS_PREVENTIVE}
            component={ShipmentErrorsPreventive}
          />
          <PrivateRoute
            path={routeParams.campaigns.ROUTE_CAMPAIGN_NEW_CAMPAIGN_PREVENTIVE}
            component={GenerateCampaignPreventive}
          />

          <PrivateRoute
            path={routeParams.campaigns.ROUTE_CAMPAIGNS_LIST}
            component={ManageShipmentsNegotiation}
          />
          <PrivateRoute
            path={routeParams.campaigns.ROUTE_CAMPAIGN_DETAILS}
            component={ShipmentDetailsNegotiation}
          />
          <PrivateRoute
            path={routeParams.campaigns.ROUTE_CAMPAIGN_ERRORS}
            component={ShipmentErrorsNegotiation}
          />
          <PrivateRoute
            path={routeParams.campaigns.ROUTE_CAMPAIGN_NEW_CAMPAIGN}
            component={GenerateCampaignNegotiation}
          />
          
          <PrivateRoute 
            path={routeParams.ROUTE_BLOCKLIST}
            component={BlockList}
          />

          <PrivateRoute path={routeParams.ROUTE_USER_PROFILE} component={UserProfile} />

          <PrivateRoute path={routeParams.ROUTE_GENERATE_REPORTS} component={GenerateReports} />

          <PrivateRoute path={routeParams.ROUTE_HEALTH_CHECK} component={HealthCheck} />

          <PrivateRoute path={routeParams.ROUTE_SYSTEM_NEWS} component={LatestNews} />

          <PrivateRoute 
            path={`${routeParams.ROUTE_SYSTEM_ERROR_BY_ID}/:docId`} 
            component={SystemIdError} 
          />
          <PrivateRoute 
            path={routeParams.ROUTE_SYSTEM_ERRORS} 
            component={SystemErrors} 
          />
        
          <PrivateRoute path={routeParams.ROUTE_CREDITOR_CONFIGURATION} component={CreditorConfiguration} />
          <PrivateRoute path={routeParams.ROUTE_INTEGRATION_CONFIGURATION} component={Integration} />

          <PrivateRoute path={routeParams.ROUTE_PROPOSAL_DENY} component={ProposalsDeny} />

          <Route path={routeParams.ROUTE_ERROR} component={Error} />
        {/* </ErrorBoundary> */}
      </Switch>
    </Router>
  );
}
