import axios from 'axios';
import { useContext } from 'react';
import { params } from '../config';
import { AuthContext } from '../context/auth';

const API_IQUITEI_URL = `${process.env.REACT_APP_API_URL}`;

console.log(API_IQUITEI_URL, 'API_IQUITEI_URL');

export const getHeaders = () => {
  const tokenSaved = localStorage.getItem(params.PARAMS_AUTH_TOKEN);

  if (!tokenSaved) {
    localStorage.clear();
    return {};
  }

  const headers = {
    Authorization: tokenSaved,
  };

  return headers;
};

export const getRequest = (url: string, config?: any) => {
  return axios.get(API_IQUITEI_URL.concat(url), {
    headers: getHeaders(),
    ...config,
  });
};

export const postRequest = (url: string, body: any, config?: any) => {
  return axios.post(API_IQUITEI_URL.concat(url), body, {
    headers: getHeaders(),
    ...config,
  });
};

// export const postMultipartRequest = (url: string, body: any, config?: any) => {
//   const options = {
//     headers: getHeaders(),
//     ...config,
//   };

//   options.headers['Content-Type'] = 'multipart/form-data';

//   return axios.post(API_IQUITEI_URL.concat(url), body, options);
// };

export const putRequest = (url: string, body: any, config?: any) => {
  return axios.put(API_IQUITEI_URL.concat(url), body, {
    headers: getHeaders(),
    ...config,
  });
};

export const deleteRequest = (url: string, config?: any) => {
  return axios.delete(API_IQUITEI_URL.concat(url), {
    headers: getHeaders(),
    ...config,
  });
};
